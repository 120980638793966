








































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FundDto, FundSendMessageInput } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "MessageMaintainList",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundMaintainList extends Vue {
  tableItems: FundDto[] = [];
  typeList: any = [];
  selection: any = [];
  form: FundSendMessageInput = {
    messageId: undefined,
    fundIds: undefined,
  };

  queryForm: any = {
    name: "",
    type: "",
  };

  created() {
    if (this.$route.query.id) {
      this.form.messageId = Number(this.$route.query.id);
    }
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  async fetchData(params: any) {
    return api.fund.getAll(params);
  }

  fetchDataList(data: any[]) {
    this.tableItems = data;
  }

  // 全选/多选
  getSelectionList(selection: any[]) {
    this.selection = selection;
  }

  // 选择发送
  sendMessages() {
    if (this.selection && this.selection.length > 0) {
      let ids: any = [];
      this.selection.forEach((item: any) => {
        ids.push(item.id);
      });
      this.form.fundIds = ids;

      api.fund
        .sendMessage({
          body: this.form,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "发送成功!",
          });
        });
    } else {
      this.$message({
        type: "error",
        message: "请选择要发送的基金!",
      });
    }
  }

  // 全部发送
  sendAllMessages() {
    if (this.tableItems && this.tableItems.length > 0) {
      let ids: any = [];
      this.tableItems.forEach((item: any) => {
        ids.push(item.id);
      });
      this.form.fundIds = ids;

      api.fund
        .sendMessage({
          body: this.form,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "发送成功!",
          });
        });
    } else {
      this.$message({
        type: "error",
        message: "请选择要发送的基金!",
      });
    }
  }
  private cancel() {
    this.$router.back();
  }
}
